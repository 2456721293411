import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

export const useIsMobile = () => {
  const breakpoints = useBreakpoint();
  const isMobile = useMemo(() => !breakpoints.lg, [breakpoints]);

  return isMobile;
};

export const useIsXsMobile = () => {
  const breakpoints = useBreakpoint();
  const isXsMobile = useMemo(() => breakpoints.xs, [breakpoints]);

  return isXsMobile;
};

export const useWindowSize = () => {
  const [windowSize, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return windowSize;
};

interface Size {
  width: number;
  height: number;
}

export const useResizeObserver = (): [
  React.RefObject<HTMLDivElement>,
  Size | null
] => {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size | null>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setSize({ width, height });
      }
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, []);

  return [ref, size];
};
