// Hook
import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// T is a generic type for value parameter
export function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

// This hook triggers a callback if the triggerVariable is true and the user has not interacted with the page for a certain amount of time
export function useInteractionTimeout(
  triggerVariable: boolean,
  delay: number,
  callback: () => void
) {
  const [hasInteracted, setHasInteracted] = useState(false);
  const hasInteractedRef = useRef(hasInteracted);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Store the timeout ID

  useEffect(() => {
    hasInteractedRef.current = hasInteracted;
  }, [hasInteracted]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear any existing timeout
    }

    if (triggerVariable) {
      timeoutRef.current = setTimeout(() => {
        if (!hasInteractedRef.current) {
          callback();
        }
      }, delay);
    } else {
      setHasInteracted(false);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clean up timeout on unmount or when triggerVariable changes
      }
    };
  }, [triggerVariable, delay, callback]);

  return {
    setHasInteracted,
  };
}

export function useDimensions(elementRef: RefObject<Element>) {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const element = elementRef.current;

    if (!element) return;
    const resizeObserver = new ResizeObserver(() => {
      setDimensions({
        width: element.getBoundingClientRect().width,
        height: element.getBoundingClientRect().height,
      });
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect(); // clean up
  }, [elementRef]);

  return dimensions;
}

export function scrollToCenter(
  elt: Element | null,
  id,
  containerSelector?: string
) {
  const container = containerSelector
    ? document.querySelector(
        ".react-horizontal-scrolling-menu--scroll-container"
      )
    : elt;
  if (container) {
    const selectedButton = container.querySelector(`#${id}`) as HTMLElement;
    if (selectedButton) {
      const containerVisibleWidth = container.clientWidth;
      const containerCenter = containerVisibleWidth / 2;
      const selectedButtonRect = selectedButton.getBoundingClientRect();

      const containerRect = container.getBoundingClientRect();

      // Calculate the position of the selected element's center relative to the container's scroll position
      const elementCenter =
        selectedButtonRect.left -
        containerRect.left +
        container.scrollLeft +
        selectedButtonRect.width / 2;
      const scrollTo = elementCenter - containerCenter;
      container.scrollTo({
        left: scrollTo,
        behavior: "smooth",
      });
    }
  }
}

export const reverseBackgroundPosition = (calcPosition: string) => {
  return calcPosition.replace("50%", "-50%");
};

export const useResizeObserver = (
  ref: React.RefObject<HTMLElement>,
  updateTrigger?: string
) => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  const updateDimensions = useCallback(() => {
    if (ref.current) {
      setDimensions({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
  }, [ref]);

  useEffect(() => {
    const container = ref.current;
    if (container) {
      const resizeObserver = new ResizeObserver(() => {
        updateDimensions();
      });

      resizeObserver.observe(container);

      // Set initial dimensions
      updateDimensions();

      return () => {
        resizeObserver.disconnect();
      };
    } else {
      return () => {};
    }
  }, [ref, updateDimensions, updateTrigger]);

  return dimensions;
};

export const reversePosition = (calcPosition: string) => {
  return calcPosition.replace("50%", "-50%");
};

export const useDragReposition = (
  url: string,
  containerRef: RefObject<HTMLDivElement>,
  enabled: boolean,
  orientation?:
    | "classic"
    | "portrait"
    | "landscape"
    | "square"
    | "video_landscape",
  initialPosition?: { x: number; y: number },
  isVideo?: boolean,
  containerLoaded?: boolean
) => {
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>(
    {
      width: 0,
      height: 0,
    }
  );

  const [position, setPosition] = useState(initialPosition || { x: 0, y: 0 });

  useEffect(() => {
    if (containerLoaded && initialPosition) {
      setPosition(initialPosition);
    }
  }, [containerLoaded]);

  const [imageSizeClassNames, setImageSizeClassNames] = useState({
    maxHeight: "100%",
    maxWidth: "100%",
  });
  const { width: containerWidth, height: containerHeight } = useResizeObserver(
    containerRef,
    String(enabled) + url
  );

  const startDrag = (startX: number, startY: number) => {
    const initialX = position.x;
    const initialY = position.y;

    const handleMove = (moveX: number, moveY: number) => {
      const deltaX = moveX - startX;
      const deltaY = moveY - startY;

      if (containerWidth && containerHeight) {
        // Maximum offsets from the center
        const maxOffsetX = (imageSize.width - containerWidth) / 2;
        const maxOffsetY = (imageSize.height - containerHeight) / 2;

        // New position relative to the center
        const newX = Math.min(
          Math.max(initialX + deltaX, -maxOffsetX),
          maxOffsetX
        );
        const newY = Math.min(
          Math.max(initialY + deltaY, -maxOffsetY),
          maxOffsetY
        );
        setPosition({
          x: Math.round(newX),
          y: Math.round(newY),
        });
      }
    };

    const handleMouseMove = (moveEvent: MouseEvent) => {
      handleMove(moveEvent.clientX, moveEvent.clientY);
    };

    const handleTouchMove = (touchEvent: TouchEvent) => {
      touchEvent.preventDefault();
      touchEvent.stopPropagation();
      handleMove(touchEvent.touches[0].clientX, touchEvent.touches[0].clientY);
    };

    const endDrag = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", endDrag);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", endDrag);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", endDrag);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", endDrag);
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    if (enabled) {
      startDrag(event.clientX, event.clientY);
    }
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    if (enabled) {
      event.stopPropagation();
      event.preventDefault();
      startDrag(event.touches[0].clientX, event.touches[0].clientY);
    }
  };

  const backgroundPosition = useMemo(() => {
    if (
      containerWidth &&
      containerHeight &&
      imageSize.width &&
      imageSize.height
    ) {
      // Calculate the limits to prevent blank spaces
      const maxOffsetX = Math.max(0, (imageSize.width - containerWidth) / 2);
      const maxOffsetY = Math.max(0, (imageSize.height - containerHeight) / 2);

      // Calculate the actual background position clamping to prevent blank spaces
      const adjustedX = Math.min(Math.max(position.x, -maxOffsetX), maxOffsetX);
      const adjustedY = Math.min(Math.max(position.y, -maxOffsetY), maxOffsetY);

      return {
        backgroundPositionX: `calc(50% + ${adjustedX}px)`,
        backgroundPositionY: `calc(50% + ${adjustedY}px)`,
      };
    }

    return {
      backgroundPositionX: `calc(50% + ${position.x}px)`,
      backgroundPositionY: `calc(50% + ${position.y}px)`,
    };
  }, [containerHeight, containerWidth, position, imageSize]);

  const videoBackgroundPosition = useMemo(() => {
    if (
      containerWidth &&
      containerHeight &&
      imageSize.width &&
      imageSize.height
    ) {
      // Calculate the limits to prevent blank spaces
      const maxOffsetX = Math.max(0, (imageSize.width - containerWidth) / 2);
      const maxOffsetY = Math.max(0, (imageSize.height - containerHeight) / 2);

      // Calculate the actual position clamping to prevent blank spaces
      const adjustedX = Math.min(Math.max(position.x, -maxOffsetX), maxOffsetX);
      const adjustedY = Math.min(Math.max(position.y, -maxOffsetY), maxOffsetY);

      // Apply positioning adjustments for the video
      return {
        top: `calc(50% + ${adjustedY}px)`,
        left: `calc(50% + ${adjustedX}px)`,
        transform: "translate(-50%, -50%)",
        width: `${imageSize.width}px`,
        height: `${imageSize.height}px`,
      };
    } else return {};
  }, [containerHeight, containerWidth, position, imageSize]);

  useEffect(() => {
    let isCurrent = true; // Flag to check if the effect is still current
    if (containerWidth && containerHeight) {
      if (isVideo) {
        const video = document.createElement("video");
        video.src = url;

        video.onloadedmetadata = () => {
          if (!isCurrent) return; // Exit if effect is outdated
          const mediaAspectRatio = video.videoWidth / video.videoHeight;
          let mediaWidth, mediaHeight;

          if (containerWidth / containerHeight > mediaAspectRatio) {
            mediaWidth = containerWidth;
            mediaHeight = containerWidth / mediaAspectRatio;
            setImageSizeClassNames({ maxWidth: "100%", maxHeight: "none" });
          } else {
            mediaHeight = containerHeight;
            mediaWidth = containerHeight * mediaAspectRatio;
            setImageSizeClassNames({ maxHeight: "100%", maxWidth: "none" });
          }
          setImageSize({ width: mediaWidth, height: mediaHeight });
        };
      } else {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          if (!isCurrent) return; // Exit if effect is outdated
          const imageAspectRatio = img.naturalWidth / img.naturalHeight;
          let imageWidth, imageHeight;
          if (containerWidth / containerHeight > imageAspectRatio) {
            imageWidth = containerWidth;
            imageHeight = containerWidth / imageAspectRatio;
            setImageSizeClassNames({ maxWidth: "100%", maxHeight: "none" });
          } else {
            imageHeight = containerHeight;
            imageWidth = containerHeight * imageAspectRatio;
            setImageSizeClassNames({ maxHeight: "100%", maxWidth: "none" });
          }
          setImageSize({ width: imageWidth, height: imageHeight });
        };
      }
    }
    return () => {
      isCurrent = false; // Cleanup: mark this effect as outdated
    };
  }, [url, containerHeight, containerWidth, orientation, enabled, isVideo]);
  return {
    handleMouseDown,
    handleTouchStart,
    position,
    setPosition,
    backgroundPosition,
    videoBackgroundPosition,
    imageSizeClassNames,
    containerSize: {
      width: containerWidth,
      height: containerHeight,
    },
    imageSize,
  };
};

export function useTapped<T extends HTMLElement>(ref: RefObject<T>) {
  useEffect(() => {
    const element = ref.current;
    if (element) {
      let touchStartY = 0;
      let touchMoved = false;

      const onTouchStart = (event: TouchEvent) => {
        touchMoved = false;
        touchStartY = event.touches[0].clientY;
        element.classList.add("tapped");
      };

      const onTouchMove = (event: TouchEvent) => {
        const touchEndY = event.touches[0].clientY;
        if (Math.abs(touchEndY - touchStartY) > 1) {
          touchMoved = true;
          element.classList.remove("tapped");
        }
      };

      const onTouchEnd = (event: TouchEvent) => {
        element.classList.remove("tapped");
        if (!touchMoved) {
          // It's a tap, not a scroll
          // You can perform additional actions here if needed
        }
      };

      element.addEventListener("touchstart", onTouchStart);
      element.addEventListener("touchmove", onTouchMove);
      element.addEventListener("touchend", onTouchEnd);

      // Cleanup on unmount
      return () => {
        element.removeEventListener("touchstart", onTouchStart);
        element.removeEventListener("touchmove", onTouchMove);
        element.removeEventListener("touchend", onTouchEnd);
      };
    } else {
      return () => {};
    }
  }, [ref]);
}
export const useSVHSuport = () => {
  useEffect(() => {
    // Function to check if a CSS unit is supported
    const supportsUnit = (unit) => {
      const div = document.createElement("div");
      div.style.height = `100${unit}`;
      return div.style.height.length > 0;
    };

    // If 'svh' is not supported, calculate and set '--svh' manually
    if (!supportsUnit("svh")) {
      const setSvh = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--svh", `${vh}px`);
      };

      // Set '--svh' on initial load
      setSvh();

      // Update '--svh' on window resize
      window.addEventListener("resize", setSvh);

      // Cleanup event listener on component unmount
      return () => window.removeEventListener("resize", setSvh);
    } else {
      return () => {};
    }
  }, []);
};
