import { ProductVariant, TrackingLink } from "@medusajs/medusa";
import { HEADER_HEIGHT_MOBILE, HEADER_HEIGHT_DESKTOP } from "constants/sizes";
import { customAlphabet } from "nanoid";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { PricedVariant } from "@medusajs/medusa/dist/types/pricing";
import slugify from "slugify";
import { getDateLocale } from "./generateDatePickerPreset";
import i18n, { availableLanguages } from "configs/i18nextConfig";

dayjs.extend(utc);
dayjs.extend(timezone);

export const getFileType = (file: File) => {
  if (file && !!file?.type) {
    if (file.type.match("image.*")) {
      return "image";
    } else if (file.type.match("video.*")) {
      return "video";
    } else if (file.type.match("audio.*")) {
      return "audio";
    } else if (file.type.match("application/pdf")) {
      return "pdf";
    } else if (file.type.match("application.*")) {
      return "doc";
    }
  }
  return "other";
};

export const randomNumber = (start = 100000, end = 999999) => {
  return Math.floor(Math.random() * end) + start;
};

export const bytesToMB = (bytes: number) => (bytes / (1024 * 1024)).toFixed(2);

export const generateDisplayId = customAlphabet(
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
  7
);

export const getHeaderHeight = (isMobile: boolean) =>
  isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP;

export function roundToClosestInteger(value: number): number {
  const roundedUp = Math.ceil(value);
  const roundedDown = Math.floor(value);

  const diffUp = Math.abs(roundedUp - value);
  const diffDown = Math.abs(roundedDown - value);

  if (diffUp < diffDown) {
    return roundedUp;
  } else {
    return roundedDown;
  }
}

// Function to get price for given quantity
export function getPriceForQuantity(
  quantity: number | null,
  prices: any[],
  storeCurrency
): number | null {
  for (const priceObj of prices) {
    const minQty = priceObj.min_quantity;
    const maxQty = priceObj.max_quantity;

    if (quantity === null) {
      if (
        minQty === null &&
        maxQty === null &&
        priceObj.currency_code === storeCurrency
      ) {
        return priceObj.amount;
      }
    } else {
      if (
        minQty !== null &&
        minQty <= quantity &&
        (maxQty === null || quantity <= maxQty) &&
        priceObj.currency_code === storeCurrency
      ) {
        return priceObj.amount;
      }
    }
  }
  return null;
}

// Function to get price for given quantity
export function getFormattedPriceForQuantity(
  quantity: number | null,
  prices: any[],
  storeCurrency
): string | null {
  const priceForQuantity = getPriceForQuantity(quantity, prices, storeCurrency);
  if (
    priceForQuantity !== null &&
    priceForQuantity !== undefined &&
    priceForQuantity !== 0
  ) {
    return `${currencySymbolMap[storeCurrency]}${(
      priceForQuantity / 100
    ).toFixed(2)}`;
  }

  return null;
}

// Function to display variant name based on variant type
export function getVariantName(
  variant: ProductVariant | PricedVariant
): string {
  return variant.metadata?.print_variant_title
    ? `${variant.metadata?.print_variant_title} print${
        !!variant.metadata?.frame_product_title
          ? ` + ${variant.metadata.frame_variant_title} ${
              variant.metadata.frame_product_color
                ? (
                    variant.metadata.frame_product_color as string
                  ).toLowerCase() + " frame"
                : (variant.metadata.frame_product_title as string).toLowerCase()
            }`
          : ""
      }${
        variant?.metadata?.ready_to_frame_product_title
          ? ` + ${variant.metadata.ready_to_frame_variant_title} ready-to-frame`
          : ""
      }`
    : variant.title || "";
}

// Dictionnary of currency symbol vs currency code lower case
export const currencySymbolMap = {
  eur: "€",
  usd: "$",
  gbp: "£",
  aud: "A$",
  cad: "C$",
  jpy: "¥",
  cny: "¥",
  brl: "R$",
};

export function formatStripePrice(
  amount: number | null,
  currency: string,
  decimals: number = 2
) {
  if (amount == null) {
    return `${currencySymbolMap[currency] || currency}-`;
  }

  const formatNumberWithCommas = (num: number) => {
    return num.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (amount >= 0) {
    return `${currencySymbolMap[currency] || currency}${formatNumberWithCommas(
      amount / 100
    )}`;
  } else {
    // Move the minus sign to the front
    return `-${currencySymbolMap[currency] || currency}${formatNumberWithCommas(
      -amount / 100
    )}`;
  }
}

export const formatDate = (date: Date) => {
  return new Date(date)?.toLocaleString(getDateLocale(i18n.language), {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

export const formatDay = (date: string | Date): string => {
  return new Date(date)?.toLocaleString(getDateLocale(i18n.language), {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export function getNearestDayOfTheWeek(dayOfWeek: string) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const targetDay = daysOfWeek.indexOf(dayOfWeek);

  if (targetDay === -1) {
    throw new Error("Invalid day of the week");
  }

  const now = new Date();
  const currentDay = now.getDay();

  // Find the next Wednesday at 06:00
  const nextWednesday = new Date(now);
  nextWednesday.setHours(6, 0, 0, 0);
  nextWednesday.setDate(now.getDate() + ((10 - currentDay) % 7));

  if (nextWednesday <= now) {
    nextWednesday.setDate(nextWednesday.getDate() + 7);
  }

  // Find the previous target day at 00:00 before the next Wednesday
  const previousTargetDay = new Date(nextWednesday);
  previousTargetDay.setDate(
    nextWednesday.getDate() - ((nextWednesday.getDay() - targetDay + 7) % 7)
  );
  previousTargetDay.setHours(0, 0, 0, 0);

  return previousTargetDay;
}

export const getTrackingUrl = (trackingLink: TrackingLink) => {
  if (trackingLink.url) {
    return trackingLink.url;
  } else {
    return `https://parcelsapp.com/en/tracking/${trackingLink.tracking_number}`;
  }
};

export const handleDatesPicker = (dates: [Dayjs, Dayjs]) => {
  const adjustedStartDate = dates[0].isBefore(
    dayjs.utc("2020-01-01").tz("Europe/Paris").startOf("day")
  )
    ? dayjs.utc("2020-01-01").tz("Europe/Paris").startOf("day")
    : dates[0].tz("Europe/Paris");
  const adjustedEndDate = dates[1].tz("Europe/Paris").endOf("day");

  return [adjustedStartDate, adjustedEndDate] as [Dayjs, Dayjs];
};

export const getPercentageChange = (current: number, previous: number) => {
  if (previous === 0) {
    return current === 0 ? 0 : 100;
  }
  return ((current - previous) / previous) * 100;
};

export const getFormattedDurationFromSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours ? hours + "h" : ""} ${
    minutes ? minutes + "m" : ""
  } ${remainingSeconds}s`;
};

export const getNumberOfDays = (dates: [Dayjs, Dayjs]) => {
  return dates[1].diff(dates[0], "d");
};

export const getFlagEmoji = (countryCode) => {
  return countryCode
    ?.toUpperCase()
    ?.replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt()));
};

export const formatFourDigitNumberToKStyle = (input: number): string => {
  const inputStr = input.toString();

  if (inputStr.length > 3) {
    return (input / 1000).toFixed(2) + "k";
  } else {
    return inputStr;
  }
};

export const getPercentage = (value: number, total: number) =>
  total ? ((value / total) * 100).toFixed(0) : "0";

export const formatNumberOverThousand = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function createSlug(title: string, handle: string): string {
  return `${slugify(title, { strict: true })}-${handle}`;
}

export function removeLocaleFromString(path: string) {
  let return_path = path;
  //Check if there is a locale in the return path
  const availableLocales = availableLanguages;
  const locale_string = path.split("/")[1];
  const isLocale = availableLocales.find(
    (locale) => locale.value === locale_string
  );
  if (isLocale) {
    //remove it from the return path
    path = return_path.replace(`/${locale_string}`, "");
  }
  return path;
}

export const capitalized = (word: string | undefined) => {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const validateUrl = (value: string) => {
  try {
    //if url does not start by http:// or https://, add it
    if (!value.startsWith("http://") && !value.startsWith("https://")) {
      value = "http://" + value;
    }

    const url = new URL(value);

    // Ensure the URL has a valid protocol (http or https)
    if (url.protocol !== "http:" && url.protocol !== "https:") {
      return Promise.reject(
        new Error("Invalid protocol. Only http and https are allowed.")
      );
    }

    // Use a regular expression to validate the domain part of the URL (for example, checking for TLD)
    const domainPattern = /^([a-zA-Z\d-]+\.)+[a-zA-Z]{2,}$/; // For domains like google.com, github.io, etc.

    if (!domainPattern.test(url.hostname)) {
      return Promise.reject(new Error("Invalid domain name."));
    }

    return Promise.resolve(); // URL is valid
  } catch (error) {
    // If URL constructor throws an error, it's not a valid URL
    return Promise.reject(new Error("Invalid URL format."));
  }
};

export const getCleanedLink = (link: string) => {
  return "https://" + link.replace(/^(?:https?:\/\/)/i, "");
};

export function isStylesheetLoaded(href: string) {
  // Convert href to an absolute URL for accurate comparison
  const link = document.createElement("a");
  link.href = href;
  const absoluteHref = link.href;

  // Query all link elements with rel="stylesheet"
  const links: NodeListOf<HTMLLinkElement> = document.querySelectorAll(
    'link[rel="stylesheet"]'
  );

  // Check if any link has the same href
  for (const existingLink of links) {
    if (existingLink.href === absoluteHref) {
      return true;
    }
  }
  return false;
}

export function moveElement<T>(
  array: T[],
  fromIndex: number,
  toIndex: number
): T[] {
  if (
    fromIndex < 0 ||
    fromIndex >= array.length ||
    toIndex < 0 ||
    toIndex >= array.length
  ) {
    throw new Error("Invalid indices");
  }

  const element = array[fromIndex];
  array.splice(fromIndex, 1); // Remove the element from its current position
  array.splice(toIndex, 0, element); // Insert the element at the new position

  return array; // Return the modified array
}

export function removeQueryParams(
  query: string,
  paramsToRemove: string[]
): string {
  // Initialize URLSearchParams with the query string (remove leading '?' if present)
  const searchParams = new URLSearchParams(
    query.startsWith("?") ? query.slice(1) : query
  );

  // Iterate over the parameters to remove and delete them from searchParams
  paramsToRemove.forEach((param) => searchParams.delete(param));

  // Convert the searchParams back to a string
  const newQuery = searchParams.toString();

  // Return the new query string with a leading '?' if there are any parameters left
  return newQuery ? `?${newQuery}` : "";
}

export function formatStorage(sizeInMB: number): string {
  if (sizeInMB < 0) {
    throw new Error("Size cannot be negative.");
  }

  const threshold = 1000;
  const size = sizeInMB;

  if (size < threshold) {
    return `${size}MB`;
  } else {
    const sizeInGB = size / 1000;
    // Format to two decimal places, remove trailing zeros
    const formattedSize = parseFloat(sizeInGB.toFixed(2));
    return `${formattedSize}GB`;
  }
}
